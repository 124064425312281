import { render, staticRenderFns } from "./MultipleAnswerComp.vue?vue&type=template&id=85b7f9ea&scoped=true"
import script from "./MultipleAnswerComp.vue?vue&type=script&lang=js"
export * from "./MultipleAnswerComp.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./MultipleAnswerComp.vue?vue&type=style&index=1&id=85b7f9ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85b7f9ea",
  null
  
)

export default component.exports